
@import "../../@core/scss/angular/libs/toastr.component.scss";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body{
  font-family: 'Quicksand', sans-serif !important;
  background-color: black !important;
  color:whitesmoke !important;
}


.dark-layout .header-navbar-shadow {
  background: black
}

.main-menu.menu-dark .navigation > li.active > a {
  //background: linear-gradient(123deg, rgba(0,242,96,1) 0%, rgba(5,117,230,1) 100%);
  box-shadow: none;
  background: linear-gradient(220deg,rgba(0,242,96,1),rgba(5,117,230,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.dark-layout .main-menu {
  background-color: #000;
  border-right: 1px solid #262626;
  //border-right: 1px solid #6e6b7b;
}
.dark-layout .main-menu-content .navigation-main {
  background-color: black;
}
//#0e1726
.text-gradient {
  background: linear-gradient(145deg,rgba(0,242,96,1),rgba(5,117,230,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color: #EEE;
  padding-left: 0.5rem;
}

.text-primary{
  color:rgba(0,242,96,1)!important;
  background: linear-gradient(145deg,rgba(0,242,96,1),rgba(5,117,230,1)) !important;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent!important;
}

a {
  color: rgba(0,242,96,1);
  text-decoration: none;
  background-color: transparent;
}

a:hover{
  color:rgba(5,117,230,1) !important;
}

.main-menu ul.navigation-main > li:first-child {
  margin-top: 2.5rem;
}

.navigation li {
  margin-top: 0.5rem;
}

.dark-layout .header-navbar {
  background-color: black;
}

p{
  line-height: 2rem!important;
}

h3{
  font-weight: 600;
}

.ngx-toastr{
  //border: 1px solid rgba(5,117,230,1);;
  border: 1px solid;
  border-image-slice: 1;
  border-radius: 2rem;
  border-image-source: linear-gradient(145deg, rgba(0,242,96,1), rgba(5,117,230,1));
}
.toast-success .toast-title {
  display: inline-block;
  background: linear-gradient(145deg,rgba(0,242,96,1),rgba(5,117,230,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.toast-success{
  background: black;
}

hr{
  border-color: #262626 !important;
}

input.input-dark{
  padding: 1.5rem 2rem;
  background-color: #262626 !important;
}

input:focus{
  border: 1px solid #404656 !important;
}

.spinner {
  width: 35px;
  height: 35px;
  background-image: linear-gradient(145deg, rgba(0,242,96,1) , rgba(5,117,230,1));
  border-radius: 50%;
  animation: spin 1.5s cubic-bezier(.78,1.54,.61,1.11) infinite;
}
.inner-circle {
  width: 25px;
  height: 25px;
  background: #000;
  border-radius: 50%;
  top: 5px;
  left: 5.2px;
  position: absolute;
}
@keyframes spin {
  0% {transform: rotate(0)}
  100% {transform: rotate(359deg)}
}

.card{
  background: rgb(0,0,0);
  background: linear-gradient(22deg, rgba(0,0,0,1) 0%, rgba(7,34,61,1) 100%);
  border: 1px solid #262626;
}

.card-row{
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px dashed #2d3747;
}

.card-row:last-child {
  border: none;
  margin-bottom: 0.5rem;
  padding-bottom: 0rem;
}

.header-navbar.floating-nav{
  margin-top: 0px;
}

.navbar-floating .header-navbar-shadow{
  height: 62px;
}
.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0.8rem 0rem;
}

.btn-gradient {
  background: linear-gradient(-45deg, #00F260, #0575E6);
  font-family: 'Quicksand';
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 6px;
  background-size: 400% 400%;
  color: #FFF !important;
  border: 0;
  animation: Gradient 5s ease infinite;

  &:hover {
    background-size: 150% 150%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
